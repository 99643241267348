
<template>
 <transition name="fade">
<v-app>
  <v-container >
    
    
  
<div style="margin-top:180px">
  
</div>
<div>
  <b-nav>
    <b-nav-item active>All</b-nav-item>
    <b-nav-item>Residential</b-nav-item>
    <b-nav-item> Road</b-nav-item>
    <b-nav-item >Worship</b-nav-item>
  </b-nav>
</div>
  <v-row align="center" >

    <v-container class="pa-4 text-center">
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <template v-for="(item, i) in items">
          <v-col
            :key="i"
            cols="12"
            md="4"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-img
                  :src="item.img"
                  height="400px"
                >
                 
                   <v-expand-transition>
            <div
              v-if="hover"
              class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal display-3 white--text"
              style="height: 50%;"
            >
             <v-row
                      class="fill-height flex-column"
                     
                     style="padding:15px"
                    >
                      <p class=" text-center">
                        
                        </p>
                        <h3>{{ item.title }}</h3>
  
                      <div>
                        <p class="ma-0 body-1 font-weight-bold font-italic text-center">
                          {{ item.text }}
                        </p>
                      
                      </div>
             </v-row>
  
            </div>
          </v-expand-transition>
                   
                
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-container>


  <!-- <v-card

 :key="i"

      :loading="loading"
      class="mx-auto my-12"
      max-width="374"
      style="margin:30px!important"
    >
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
  
      <v-img
        height="250"
        src="@/assets/img/timthumb_015.jpeg"
      ></v-img>
  
      <v-card-title>Charity Bay-view</v-card-title>
  
      <v-card-text>

        <div>Location:  Phase II, Lekki, Lagos, Nigeria. - 
         </div>
          <div> Levels: 3 suspended floors
         </div>
      </v-card-text>
      
  
     
  
    
    </v-card> -->
    </v-row>
    
  </v-container>

 <!-- <div style="position:fixed;bottom:0;padding: 10px;padding-left: 10vw;padding-right: 10vw;background: #63b982 !important;">
    <b-container fluid >
       <b-row >
                    <div class="col-md-6 col-lg-3">
                        <div class="footer-about">
                            <h3>About Us</h3>
                            <p>
                                Lorem ipsum dolor sit amet elit. Quisque eu lectus a leo dictum nec non quam. Tortor eu placerat rhoncus, lorem quam iaculis felis, sed lacus neque id eros
                            </p>
                            <div class="footer-social">
                                <a href=""><i class="fab fa-twitter"></i></a>
                                <a href=""><i class="fab fa-facebook-f"></i></a>
                                <a href=""><i class="fab fa-youtube"></i></a>
                                <a href=""><i class="fab fa-instagram"></i></a>
                                <a href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="footer-contact">
                            <h3>Get In Touch</h3>
                            <p><i class="fa fa-map-marker-alt"></i>123 Street, New York, USA</p>
                            <p><i class="fa fa-phone-alt"></i>+012 345 67890</p>
                            <p><i class="fa fa-envelope"></i>info@example.com</p>
                            <p><i class="far fa-clock"></i>Mon - Fri, 9AM - 10PM</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="footer-links">
                            <h3>Useful Links</h3>
                            <a href="">Lorem ipsum</a>
                            <a href="">tempus posuere </a>
                            <a href="">velit id accumsan</a>
                            <a href="">ut porttitor</a>
                            <a href="">Nam pretium</a>
                            <a href="">accumsan</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="footer-project">
                            <h3>Latest Projects</h3>
                            <a href=""><img src="img/img-1.jpg" alt="Image"></a>
                            <a href=""><img src="img/img-2.jpg" alt="Image"></a>
                            <a href=""><img src="img/img-3.jpg" alt="Image"></a>
                            <a href=""><img src="img/img-4.jpg" alt="Image"></a>
                            <a href=""><img src="img/img-5.jpg" alt="Image"></a>
                            <a href=""><img src="img/img-6.jpg" alt="Image"></a>
                        </div>
                    </div>
       </b-row>
       
         
    </b-container>

</div> -->
</v-app>
 </transition>

</template>

<script>
  export default {
   
    data() {
      return {
        dismissSecs: 10,
        dismissCountDown: 0,
        showDismissibleAlert: false,
         items: [
      {
        title: 'House On The Rock',
        text: `This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;`,
        subtext: '',
        img:  require('../assets/img/Picture10.png'),
      },
      {
        title: 'Church Of Transfiguration',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture12.png'),
      },
      {
        title: 'Mellow Moods',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture14.png'),
      }, {
        title: 'House On The Rock',
        text: `This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;`,
        subtext: '',
        img:  require('../assets/img/Picture10.png'),
      },
      {
        title: 'Church Of Transfiguration',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture12.png'),
      },
      {
        title: 'Mellow Moods',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture14.png'),
      }, {
        title: 'House On The Rock',
        text: `This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;`,
        subtext: '',
        img:  require('../assets/img/Picture10.png'),
      },
      {
        title: 'Church Of Transfiguration',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture12.png'),
      },
      {
        title: 'Mellow Moods',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture14.png'),
      }, {
        title: 'House On The Rock',
        text: `This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;`,
        subtext: '',
        img:  require('../assets/img/Picture10.png'),
      },
      {
        title: 'Church Of Transfiguration',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture12.png'),
      },
      {
        title: 'Mellow Moods',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture14.png'),
      }, {
        title: 'House On The Rock',
        text: `This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;`,
        subtext: '',
        img:  require('../assets/img/Picture10.png'),
      },
      {
        title: 'Church Of Transfiguration',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture12.png'),
      },
      {
        title: 'Mellow Moods',
        text: 'This auditorium has a foot plate (plan area) of approximately 10000m2 and has raking gallery on three levels;',
        subtext: '',
        img:  require('../assets/img/Picture14.png'),
      },
    ],
    items2:[
      { title: 'All' },
      { title: 'Residential' },
      { title: 'Commercial' },
      { title: 'Educational/Recreational' },
       { title: 'Worship' },
    ],
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    }
  }
</script>

<style scoped>
.v-card {
  opacity:0.5;
transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
opacity: 1;
 }

.show-btns {
color: rgba(255, 255, 255, 1) !important;
}
.v-card--reveal {
align-items: center;
bottom: 0;
justify-content: center;
opacity: 1;
position: absolute;
width: 100%;
}
</style>