
<template>
<div>
   <contact />
</div>

</template>

<script>
 
import contact from "../components/ContactSection";

  export default {
     components:{
      
    contact
    },
    data() {
      return {
        dismissSecs: 10,
        dismissCountDown: 0,
        showDismissibleAlert: false
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    }
  }
</script>

<style scoped>

</style>