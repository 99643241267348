
<template>
<div id="background" >
   <v-container  fluid id="features" class="mt-2">
      <v-row style="margin-top:120px" align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <!-- <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Title</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h1>
            </v-col> -->
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  <download />
       <!-- <about/> -->
</div>

</template>

<script>
// import about from "../components/AboutSection";
import download from "../components/DownloadSection";
  export default {
     components:{
         download,
    // about,
    },
    data() {
      return {
        dismissSecs: 10,
        dismissCountDown: 0,
        showDismissibleAlert: false,
          features: [
        {
          img: require("../assets/img/icon2.png"),
          title: "Our vision",
          text: "To be the leading innovative global brand in built environment.",
        },
        {
          img: require("../assets/img/icon1.png"),
          title: "Commitment",
          text: " We are committed to creating the most fulfilling work environment based on trust and personal responsibility; encourages open communication, continued learning, innovative thinking, and teamwork; and provide each individual, regardless of position, an opportunity for personal, professional, and financial growth that is unequaled in our profession.",
        },
        {
          img: require("../assets/img/icon3.png"),
          title: "Values",
          text: "Professionalism, innovation, team work and excellent customer services ",
        },
      ],
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    }
  }
</script>

<style scoped>
#background {
  background-image: url("~@/assets/img/Picture10.png");
 width: 100%;
   background-size: cover ;
}
</style>